<template>
    <div class="multipart-selector">
        <button type="button" class="close-multipart-selector btn" @click="$emit('hide-multipart-selector')">
            <img src="@assets/img/icons/left-chevron.svg">
        </button>
        <div class="form-group">
            <input
                id="memo-title"
                :value="memoTitle"
                type="text"
                class="form-control"
                placeholder="Title of your multipart"
                maxlength="100"
                @input="(event) => $emit('on-title-change', event.target.value)"
            >
        </div>
        <div class="memo-parts">
            <draggable :list="memoParts" handle=".re-order" @update="setEditorContent">
                <div v-for="(part, index) in memoParts" :key="index" class="memo-part">
                    <div class="re-order">
                        <img
                            src="@assets/img/icons/bullet-order.svg"
                            alt="Bullet Order"
                        >
                    </div>
                    <button class="btn" type="button" @click="changeCurrentPart(index)">
                        {{ index + 1 }}. {{ part.title }}
                    </button>
                    <v-popover
                        class="more-options"
                    >
                        <div class="tooltip-target b3">
                            <img src="@assets/img/icons/three-dots.svg" alt="More options">
                        </div>
                        <template slot="popover">
                            <div class="popover-options">
                                <div v-close-popover class="popover-option" @click="deletePart(index)">
                                    <span>Delete</span>
                                    <img
                                        src="@assets/img/icons/trash-can.svg"
                                        class="delete"
                                        alt="delete"
                                    >
                                </div>
                            </div>
                        </template>
                    </v-popover>
                </div>
            </draggable>
        </div>
        <div v-tooltip.bottom="areAllPartsValid ? '' : 'One or more part(s) have no title and/or bullets, you must provide</br>each part the needed info before creating a new one'" class="add-part-container">
            <button class="add-part btn" :disabled="!areAllPartsValid" @click="addPart">
                Add a Part
            </button>
            <span>Whenever you add a Part, it will be automatically numbered and positioned last. You can reorder them by click and dragging them to your liking.</span>
        </div>
    </div>
</template>

<script>
import draggable from "vuedraggable";

export default {
    name: "MultipartSelector",
    components: {
        draggable
    },
    props: {
        memoTitle: {
            type: String,
            default: ""
        },
        memoParts: {
            type: Array,
            default() {
                return [];
            }
        },
        areAllPartsValid: {
            type: Boolean,
            default: false
        }
    },
    methods: {
        addPart() {
            this.$emit("add-part");
        },
        deletePart(index) {
            this.$emit("delete-part", index);
        },
        changeCurrentPart(index) {
            this.$emit("change-current-part", index);
            this.$emit("hide-multipart-selector");
        },
        setEditorContent() {
            this.$emit("set-editor-content");
        }
    }
}
</script>

<style lang="scss" scoped>
.multipart-selector {
    width: 275px;
    height: 100%;
    display: flex;
    flex-direction: column;

    .close-multipart-selector {
        padding: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 26px;
        height: 26px;
        margin-bottom: 40px;
        display: none;

        img {
            width: 9px;
        }

        &:focus {
            box-shadow: none;
        }
    }

    #memo-title {
        font-family: 'Gibson';
        font-size: 2.13em;

        @media(max-width: $md) {
            font-size: 22px;
        }
    }

    .memo-parts {
        overflow: auto;
        padding-right: 5px;

        .memo-part {
            position: relative;
            margin-bottom: 10px;
            display: flex;
            align-items: stretch;

            &:last-child {
                margin-bottom: 0;
            }

            .re-order {
                cursor: pointer;
                min-width: 26px;
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: grabbing;
            }

            .btn {
                padding: 10px;
                border-radius: 8px;
                text-align: left;
                background-color: #2C2C2E;
                color: white;
                width: 100%;
                padding-right: 50px;
                font-size: 16px;
                line-height: 18px;
                font-weight: 400;

                &:focus {
                    box-shadow: none;
                }
            }

            .more-options {
                position: absolute;
                right: 0;
                top: 0;
                width: 50px;
                height: 100%;
                padding-top: 13px;
                display: flex;
                justify-content: center;
                cursor: pointer;
            }
        }
    }

    .add-part-container {
        margin-left: 26px;
        margin-right: 5px;
        margin-top: 10px;

        .add-part {
            width: 100%;
            background-color: #2C2C2E;
            border-radius: 8px;
            color: $primary-color;
            padding: 10px;

            &:focus {
                box-shadow: none;
            }
        }

        span {
            font-size: 11px;
            color: rgba(235, 235, 245, 0.3);
            line-height: 15px;
            display: block;
            margin-top: 10px;
        }
    }
}
</style>
